import React, { createContext } from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'

export const ProjectContext = createContext({
  title: '',
  categories: [],
  location: '',
})

interface PageTemplateProps {
  data: {
    page: {
      path?: string
      title: string
      projectcategories?: any
      recap?: any
      seo: any
      flexContent: any
    }
  }
  location?: any
  prefix?: string
}

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: {
    page: { title, projectcategories, recap, flexContent, seo },
  },
  location = {},
  prefix = 'Project_Flexcontent',
}) => (
  <Layout>
    <SEO seo={seo} />
    <ProjectContext.Provider
      value={{
        title,
        categories: projectcategories?.nodes,
        location: recap?.location.name,
      }}
    >
      <FlexContentHandler
        prefix={prefix}
        fields={flexContent}
        location={location}
      />
    </ProjectContext.Provider>
  </Layout>
)

export const pageQuery = graphql`
  query projectById($pageId: String!) {
    page: wpProject(id: { eq: $pageId }) {
      ...generalProjectFragment
    }
  }
`

export default PageTemplate
